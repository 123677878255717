



















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question518',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
        language: 'fr',
      },
      options: [
        {text: 'Molecule A', value: 'a'},
        {text: 'Molecule B', value: 'b'},
        {text: 'Molecule C', value: 'c'},
        {text: 'Molecule D', value: 'd'},
      ],
      optionsFr: [
        {text: 'Molécule A', value: 'a'},
        {text: 'Molécule B', value: 'b'},
        {text: 'Molécule C', value: 'c'},
        {text: 'Molécule D', value: 'd'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q518.png';
    },
  },
};
